(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/sport/sport-widget-mini/assets/javascripts/sportradar-widget-mini.js') >= 0) return;  svs.modules.push('/components/sport/sport-widget-mini/assets/javascripts/sportradar-widget-mini.js');
"use strict";


const {
  SportradarWidget
} = svs.components.sport.sportWidget;
const {
  MATCH_PREVIEW
} = svs.sport.sportradar.constants;
const preventDefault = event => {
  event.preventDefault();
};
const widgetMatchPreviewOptions = {
  disableMoreStats: true,
  isCollapsed: true,
  disableExpandButton: true
};
const SportradarWidgetMini = _ref => {
  let {
    matchId
  } = _ref;
  return React.createElement(SportradarWidget, {
    className: "sportradar-mini",
    matchId: matchId,
    onClick: preventDefault,
    widgetProps: widgetMatchPreviewOptions,
    widgetType: MATCH_PREVIEW
  });
};
SportradarWidget.propTypes = {
  matchId: PropTypes.string
};
setGlobal('svs.components.sport.sportWidgetMini.SportradarWidgetMini', SportradarWidgetMini);

 })(window);